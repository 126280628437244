import type { GetStaticPropsContext, GetStaticPropsResult } from 'next';

import Fixture404 from '@web/services/mocks/documentFixtures/404.fixture.json';

import { Preview } from '@apps/web/src/components/preview/preview';
import Config from '@apps/web/src/config';
import Page from '@apps/web/src/layouts/page';
import { GlobalContextProvider } from '@apps/web/src/layouts/page/context';
import { fetchUmbracoData } from '@apps/web/src/services/umbraco/fetch';
import { buildLinkForEdit } from '@apps/web/src/services/umbraco/helpers';
import { MappedRootData } from '@apps/web/src/services/umbraco/queries/root.query';
import { getRootUrl, normalizeSlug } from '@apps/web/src/services/umbraco/route-mapping';
import { mapDocument } from '@apps/web/src/services/umbraco/type-mapping';
import { mapSlugToDocumentType, TemplateKey } from '@apps/web/src/templates';
import ErrorPageTemplate, { ErrorPageData } from '@apps/web/src/templates/errorpage';

export type Custom404Props<T extends TemplateKey = TemplateKey> = {
	preview: boolean;
	docType: T;
	rootData: MappedRootData;
	routeData: ErrorPageData | null;
	eventId: string | null;
};

export default function Custom404({ preview, rootData, routeData }: Custom404Props) {
	if (routeData) {
		return (
			<GlobalContextProvider rootData={rootData} preview={preview} routeData={routeData}>
				<Page>
					<ErrorPageTemplate pageData={routeData} />
					{preview && <Preview editUrl={buildLinkForEdit(routeData.id)} redeployUrl={Config.VERCEL_REDEPLOY_URL} />}
				</Page>
			</GlobalContextProvider>
		);
	}

	return (
		<GlobalContextProvider rootData={rootData}>
			<Page>
				<ErrorPageTemplate pageData={mapDocument<ErrorPageData>(Fixture404.contentByAbsoluteRoute)} />
				{preview && <Preview redeployUrl={Config.VERCEL_REDEPLOY_URL} />}
			</Page>
		</GlobalContextProvider>
	);
}

export async function getStaticProps({
	preview = false,
	params,
}: GetStaticPropsContext): Promise<GetStaticPropsResult<Custom404Props>> {
	const slug = normalizeSlug(params?.slug);
	const slugMap = await mapSlugToDocumentType(slug, { preview });
	const rootUrl = getRootUrl(slugMap.queryParams.slug);

	slugMap.queryParams.slug = rootUrl.endsWith('/') ? `${rootUrl}404` : `${rootUrl}/404`;

	const { rootData, routeData } = await fetchUmbracoData(slugMap);

	// If we're here, it means there's data from minimum one of the services and it's considered a valid slug.
	return {
		props: {
			preview,
			docType: slugMap?.docType,
			rootData,
			routeData: routeData as ErrorPageData | null,
			eventId: null,
		},
		revalidate: false,
	};
}
