import type { NextPage } from 'next';
import { useRouter } from 'next/router';

import { ErrorFrame } from '@web/components/error-frame/error-frame';

import TemplateLayout from '@web/layouts/template/template';

import { UmbracoErrorType } from '@web/services/umbraco/types/basic/ErrorTypes';
import { QBankVectorType } from '@web/services/umbraco/types/basic/QBank';
import { HTMLString, RichTextContent } from '@web/services/umbraco/types/basic/RichTextContent';
import { UmbracoPage } from '@web/services/umbraco/types/documents/UmbracoDocument';
import { LinkItemBlock } from '@web/services/umbraco/types/elements/Action';

export const contentAlias = 'errorPage';

export type ErrorPageData = UmbracoPage<
	typeof contentAlias,
	{
		errorType: UmbracoErrorType;
		umbracoUrlName?: string;
		illustration?: QBankVectorType;
		title: HTMLString;
		subtitle?: RichTextContent;
		link?: LinkItemBlock;
		textBelowLink: RichTextContent;
	}
>;

const ErrorPageTemplate: NextPage<{ pageData: ErrorPageData; eventId?: string | null }> = ({ pageData, eventId }) => {
	const { errorType } = pageData.properties;
	const { query } = useRouter();
	const callbackUrl = Array.isArray(query.callbackUrl) ? query.callbackUrl[0] : query.callbackUrl;

	return (
		<TemplateLayout>
			<ErrorFrame pageData={pageData} eventId={eventId} errorType={errorType} callbackUrl={callbackUrl} />
		</TemplateLayout>
	);
};

export default ErrorPageTemplate;
